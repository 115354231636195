import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
import { CodeBlock } from "../../../src/components/DigitalGarden";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "Git"`}</p>



    <h2 {...{
      "id": "git",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#git",
        "aria-label": "git permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#merge-dev-branch-into-master"
        }}>{`Merge dev branch into master`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-one-repository-with-folders-of-different-projects"
        }}>{`Create one repository with folders of different projects`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#change-commit-message-of-the-last-commit"
        }}>{`Change commit message of the last commit`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#checkout-pull-requests-locally"
        }}>{`Checkout pull requests locally`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#tags"
        }}>{`Tags`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#nice-git-log"
        }}>{`Log`}</a></li>
    </ol>
    <h3 {...{
      "id": "merge-dev-branch-into-master",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#merge-dev-branch-into-master",
        "aria-label": "merge dev branch into master permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Merge dev branch into master`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`On dev branch`}</p>
        <CodeBlock mdxType="CodeBlock">{`git merge master`}</CodeBlock>- to solve merge conflicts if any
      </li>
      <li parentName="ul">
        <p parentName="li">{`Checkout to master branch`}</p>
      </li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git checkout master`}</CodeBlock>
    <ul>
      <li parentName="ul">{`Merge dev branch into master branch`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git merge --no-ff dev`}</CodeBlock>
    <img src="/media/git-merge.png" />
    <ul>
      <li parentName="ul">{`Push master branch`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git push origin master`}</CodeBlock>
    <ul>
      <li parentName="ul">{`Delete merged branch`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git branch -d dev`}</CodeBlock>
    <ul>
      <li parentName="ul">{`Delete merged branch on remote host`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git push origin :dev`}</CodeBlock>
    <hr></hr>
    <h3 {...{
      "id": "create-one-repository-with-folders-of-different-projects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-one-repository-with-folders-of-different-projects",
        "aria-label": "create one repository with folders of different projects permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create one repository with folders of different projects`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create github repository with readme file`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Clone it`}</p>
        <CodeBlock mdxType="CodeBlock">{`git clone <repo_url>`}</CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unzip cloned folder`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy folder with project into this folder`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From root folder add all files to git`}</p>
        <CodeBlock mdxType="CodeBlock">{`git add .`}</CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commit changes`}</p>
        <CodeBlock mdxType="CodeBlock">{`git commit -m “message”`}</CodeBlock>{" "}
      </li>
      <li parentName="ol">
        <p parentName="li">{`Push changes`}</p>
        <CodeBlock mdxType="CodeBlock">{`git push origin master`}</CodeBlock>{" "}
      </li>
    </ol>
    <p>{`To add another folder repeat from 4th step.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "change-commit-message-of-the-last-commit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#change-commit-message-of-the-last-commit",
        "aria-label": "change commit message of the last commit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Change commit message of the last commit`}</h3>
    <CodeBlock mdxType="CodeBlock">{`git commit --amend -m "your new message"`}</CodeBlock>
    <hr></hr>
    <h3 {...{
      "id": "checkout-pull-requests-locally",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#checkout-pull-requests-locally",
        "aria-label": "checkout pull requests locally permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Checkout pull requests locally`}</h3>
    <ol>
      <li parentName="ol">
        <CodeBlock mdxType="CodeBlock">{`git fetch origin pull/ID/head:BRANCHNAME`}</CodeBlock>
      </li>
    </ol>
    <p>{`where`}</p>
    <ul>
      <li parentName="ul">{`ID - pull request's ID (#18)`}</li>
      <li parentName="ul">{`BRANCHNAME - a name of the branch`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <CodeBlock mdxType="CodeBlock">{`git checkout BRANCHNAME`}</CodeBlock>
      </li>
    </ol>
    <p>{`For there, you can run the code locally, make changes, then push the new branch up.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.github.com/en/github/collaborating-with-issues-and-pull-requests/checking-out-pull-requests-locally"
      }}>{`Docs`}</a></p>
    <hr></hr>
    <h3 {...{
      "id": "tags",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tags",
        "aria-label": "tags permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tags`}</h3>
    <ul>
      <li parentName="ul">{`list all your tags`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git tag`}</CodeBlock>
    <ul>
      <li parentName="ul">{`delete tag`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git tag -d <tag_name>`}</CodeBlock>
    <ul>
      <li parentName="ul">{`create tag`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git tag <tag_name>`}</CodeBlock>
    <ul>
      <li parentName="ul">{`push tags`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">{`git push origin --tags`}</CodeBlock>
    <p><a parentName="p" {...{
        "href": "https://git-scm.com/book/en/v2/Git-Basics-Tagging"
      }}>{`Docs`}</a></p>
    <hr></hr>
    <h3 {...{
      "id": "nice-git-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#nice-git-log",
        "aria-label": "nice git log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nice Git Log`}</h3>
    <CodeBlock mdxType="CodeBlock">{`git log --graph --oneline --decorate`}</CodeBlock>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      